import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import { useRecoilState } from "recoil";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import logo from "../../assets/images/createaccountlogo.svg";
import "../../assets/css/Userdomain.css";
import group63 from "../../assets/images/group63.svg";
import googlelogo from "../../assets/images/Google logo.svg";
import balloneBig from "../../assets/images/Ellipse-1.svg";
import balloneSmall from "../../assets/images/Ellipse-2.svg";
import mobilelogo from "../../assets/images/logomobile.svg";
import eyeclose from "../../assets/images/eye-hide.svg";
import eyeopen from "../../assets/images/eye-open.svg";
import BASE_URL from "../../services/index";
import {
  userName,
  userEmail,
  userPhoneNumber,
  userPassword,
} from "../../atoms/authatoms/authAtoms";
import { ResponseModal } from "../dashboard/components/VerifyModal";

const Label = ({ html, title }) => {
  return (
    <label
      className="font-[400] text-[14.3883px] leading-[26px] tracking-[0.025em] text-[#1A1A33]"
      htmlFor={html}
    >
      {title}
    </label>
  );
};

const CreateAccount = () => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [response, setResponse] = useState();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const location = useLocation();

  const getReferralCodeFromURL = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("invite_code") || "";
  };

  useEffect(() => {
    const storedEmail = localStorage.getItem("userEmail");
    if (storedEmail) {
      setEmail(storedEmail);
    }
    setReferralCode(getReferralCodeFromURL());
  }, [location]);

  const schema = yup.object().shape({
    fullName: yup.string().required(),
    email: yup.string().email().required(),
    phoneNumber: yup.string().min(11).max(11).required(),
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.]).{8,}$/,
        "Should include an uppercase, lowercase, special characters, and digits (minimum of 8 characters)"
      )
      .required(),
    referralCode: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const endpoint = "/auth/onboarding";

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const onSubmit = (data) => {
    // console.log(data)
    setIsSubmitting(true);

    axios
      .post(BASE_URL + endpoint, data)
      .then((response) => {
        console.log(response.data.message);
        setModalMessage(response.data.message);
        setIsResponseModalOpen(true);
        setIsSubmitting(false);
        localStorage.setItem("userEmail", data.email);
        // setTimeout(() => {
        // 	navigate('/questions');
        // }, 9000);
      })
      .catch((error) => {
        console.error(error.data.Message);
        setError(error.response.data.Message);
        setIsResponseModalOpen(false);
        setIsSubmitting(false);
        // setFullName('');
        // setEmail('');
        // setPhoneNumber('');
        // setPassword('');
        setTimeout(() => {
          setError("");
        }, 10500);
      });
  };

  return (
    <>
      {isTabletOrMobile ? (
        <>
          <div className="bg-white py-12 px-4 changa">
            <div className="mb-4">
              <img src={mobilelogo} alt="" />
            </div>
            <div className="mb-5">
              <h1 className="changa-bold text-lg text-[#414080]">
                Create your HelpAI account
              </h1>
            </div>
            <div>
              <form className="mb-6" onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-5">
                  <Label html="Full Name" title="Full Name" />
                  <input
                    required
                    type="text"
                    {...register("fullName")}
                    className="bg-white rounded-lg border border-1 border-[#CFCFCF] outline-none w-full px-6 py-3"
                    placeholder="ex. John Doe"
                  />
                  <p className="text-red-500">{errors.fullName?.message}</p>
                </div>
                <div className="mb-5">
                  <Label html="Email Address" title="Email Address" />
                  <input
                    required
                    type="email"
                    {...register("email")}
                    className="bg-white rounded-lg border border-1 border-[#CFCFCF] outline-none w-full px-6 py-3"
                    placeholder="ex. johndoe@gmail.com"
                  />
                  <p className="text-red-500">{errors.email?.message}</p>
                </div>
                <div className="mb-5">
                  <Label html="Phone Number" title="Phone Number" />
                  <input
                    required
                    type="text"
                    {...register("phoneNumber")}
                    className="bg-white rounded-lg border border-1 border-[#CFCFCF] outline-none w-full px-6 py-3"
                    placeholder="ex. (123)801-111-5000"
                  />
                  <p className="text-red-500">{errors.phoneNumber?.message}</p>
                </div>
                <div className="mb-5">
                  <Label html="Password" title="Password" />
                  <div className="relative">
                    <input
                      id="password"
                      required
                      type={showPassword ? "text" : "password"}
                      {...register("password")}
                      title="Password should include an uppercase, lowercase, special characters and digits (minimum 8 characters)"
                      className="w-full h-full rounded-[8px] border border-1 border-[#CFCFCF] mt-[8px] outline-none"
                    />
                    <div
                      className="absolute top-5 right-2 transition-all duration-300 text-gray-500"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? (
                        <img src={eyeopen} alt="" />
                      ) : (
                        <img src={eyeclose} alt="" />
                      )}
                    </div>
                  </div>
                  <p className="text-red-500">{errors.password?.message}</p>
                </div>
                <div className="mb-14">
                  <Label html="Referral Code" title="Referral Code" />
                  <input
                    type="text"
                    {...register("referralCode")}
                    className="bg-white rounded-lg border border-1 border-[#CFCFCF] outline-none w-full px-6 py-3"
                    placeholder="ex. HWOJS-1274292"
                    value={referralCode}
                    onChange={(e) => setReferralCode(e.target.value)}
                  />
                  <p className="text-red-500">{errors.referralCode?.message}</p>
                </div>
                <button
                  type="submit"
                  className={`${
                    isSubmitting
                      ? "w-full h-[49px] bg-[#414080] text-[#FFFFFF] rounded-[8px] cursor-not-allowed opacity-50"
                      : "w-full h-[49px] bg-[#414080] text-[#FFFFFF] rounded-[8px]"
                  }`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Processing, please wait..." : "Continue"}
                </button>
              </form>
              <div className="flex justify-center items-center mb-[41px]">
                <img src={group63} alt="" />
              </div>
              {/* <a href="https://api.helpai.co/auth/google"> */}
                <button
                  className={clsx([
                    "inputbox w-full h-[49px] flex justify-center items-center gap-[10px] mb-[29px] blur",
                  ])}
                >
                  <div>
                    <img src={googlelogo} alt="Google Logo" />
                  </div>
                  <div>
                    <p>Sign up with Google</p>
                  </div>
                </button>
              {/* </a> */}
              <div className="flex justify-center items-center">
                <p>
                  Already have an account?{" "}
                  <Link to="/login">
                    <span className="text-[#5655AA]">Login</span>
                  </Link>
                </p>
              </div>
            </div>
            <ResponseModal
              isOpen={isResponseModalOpen}
              message={modalMessage}
              onClose={() => setIsResponseModalOpen(false)}
            />
            {/*  */}
            <AnimatePresence>
              {error && (
                <motion.div
                  initial={{ opacity: 0, x: "100%" }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: "100%" }}
                  transition={{ duration: 0.5 }}
                  className="text-white text-xl px-[21px] py-4 absolute top-7 right-2 bg-[#5655AA] flex justify-center items-center rounded-lg"
                >
                  <p>{error}</p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </>
      ) : (
        <div className="bg-[#F8F9FC] flex justify-center text-start changa h-screen overflow-y-auto">
          <div className="mt-[65px]">
            <Link to="/">
              <div className="mb-[28.63px]">
                <img src={logo} alt="" />
              </div>
            </Link>

            <div className="relative">
              <div className="absolute z-10 -top-8 -right-16 sm:top-4 sm:-right-16">
                <img className="w-56 h-56" src={balloneBig} alt="Ballone" />
              </div>

              <div className="relative z-10 w-[498px] h-[918px] bg-[#FFFFFF] rounded-lg form px-[40px] pt-[40px] shadow-[20px_40px_100px_rgba(148,148,148,0.1)]">
                <div className="mb-10">
                  <h2 className="font-semibold text-2xl leading-[0.02em] text-[#414080]">
                    Create your HelpAI account
                  </h2>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className="mb-[25px]">
                  <div className="mb-[18px]">
                    <label htmlFor="fullName" className="font-semibold">
                      Full Name
                    </label>
                    <input
                      id="fullName"
                      required
                      type="text"
                      className="w-[417px] h-[52px] rounded-[8px] inputbox p-3 mt-[8px]"
                      placeholder="ex. John Doe"
                      {...register("fullName")}
                    />
                    <p className="text-red-500">{errors.fullName?.message}</p>
                  </div>

                  <div className="mb-[18px]">
                    <label htmlFor="email" className="font-semibold">
                      Email Address
                    </label>
                    <input
                      id="email"
                      required
                      type="email"
                      className="w-[417px] h-[52px] rounded-[8px] inputbox p-3 mt-[8px]"
                      placeholder="ex. johndoe@gmail.com"
                      {...register("email")}
                    />
                    <p className="text-red-500">{errors.email?.message}</p>
                  </div>

                  <div className="mb-[18px]">
                    <label htmlFor="phone" className="font-semibold">
                      Phone Number
                    </label>
                    <input
                      id="phone"
                      required
                      type="tel"
                      className="w-[417px] h-[52px] rounded-[8px] inputbox p-3 mt-[8px]"
                      placeholder="ex. (123)801-111-5000"
                      {...register("phoneNumber")}
                    />
                    <p className="text-red-500">
                      {errors.phoneNumber?.message}
                    </p>
                  </div>

                  <div className="">
                    <label htmlFor="password" className="font-semibold">
                      Password
                    </label>
                    <div className="relative">
                      <input
                        id="password"
                        required
                        type={showPassword ? "text" : "password"}
                        title="Password should include an uppercase, lowercase, special characters and digits (minimum 8 characters)"
                        className="relative w-[417px] h-[51px] rounded-[8px] inputbox p-3 mt-[8px] outline-none"
                        {...register("password")}
                      />
                      <div
                        className="absolute top-5 right-2 transition-all duration-300 text-gray-500"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <img src={eyeopen} alt="" />
                        ) : (
                          <img src={eyeclose} alt="" />
                        )}
                      </div>
                    </div>
                    <p className="text-red-500">{errors.password?.message}</p>
                  </div>
                  <div className="mt-1 mb-3">
                    <p className="text-[10px] text-[#878787]">
                      Password should include an uppercase, lowercase, special
                      characters and digits
                    </p>
                  </div>
                  <div className="mb-[63px]">
                    <label htmlFor="phone" className="font-semibold">
                      Referral Code(Optional)
                    </label>
                    <input
                      id="refferal"
                      type="text"
                      className="w-[417px] h-[52px] rounded-[8px] inputbox p-3 mt-[8px]"
                      placeholder="ex. HWOJS-1274292"
                      {...register("referralCode")}
                      value={referralCode}
                      onChange={(e) => setReferralCode(e.target.value)}
                    />
                    <p className="text-red-500">
                      {errors.referralCode?.message}
                    </p>
                  </div>

                  <button
                    type="submit"
                    className={clsx([
                      "w-[419px] h-[49px] bg-[#414080] text-[#FFFFFF] rounded-[8px]",
                      {
                        "cursor-not-allowed opacity-50": isSubmitting,
                      },
                    ])}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Processing, please wait..." : "Continue"}
                  </button>
                </form>
                <div>
                  <div className="flex justify-center items-center mb-[41px]">
                    <img src={group63} alt="" />
                  </div>
                  {/* <a href="https://api.helpai.co/auth/google"> */}
                    <button
                      // onClick={handleGoogleAuth}
                      className={clsx([
                        "inputbox w-[419px] h-[49px] flex justify-center items-center gap-[10px] mb-[29px] blur",
                      ])}
                    >
                      <div>
                        <img src={googlelogo} alt="Google Logo" />
                      </div>
                      <div>
                        <p>Sign up with Google</p>
                      </div>
                    </button>
                  {/* </a> */}
                  <div className="flex justify-center space-x-1 font-[400] text-[14.3883px] leading-[26px]">
                    <p>Already have an account? </p>
                    <Link to="/login" className="text-[#5655AA] font-semibold">
                      Login
                    </Link>
                  </div>
                </div>
              </div>

              <div className="absolute z-5 -bottom-10 -left-20 sm:-bottom-8">
                <img className="w-36 h-36" src={balloneSmall} alt="Ballone" />
              </div>
            </div>
          </div>
          <ResponseModal
            isOpen={isResponseModalOpen}
            message={modalMessage}
            onClose={() => setIsResponseModalOpen(false)}
          />
          {/*  */}
          <AnimatePresence>
            {error && (
              <motion.div
                initial={{ opacity: 0, x: "100%" }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "100%" }}
                transition={{ duration: 0.5 }}
                className="text-white text-xl px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg z-10"
              >
                <p>{error}</p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
    </>
  );
};

export default CreateAccount;
